import { ArrowBack } from '@mui/icons-material'
import { Button, Stack, Typography } from '@mui/material'
import { useRouter } from 'next/router'
import theme from 'src/theme/theme'

export default function PageNotFound() {
  const { back } = useRouter()

  return (
    <Stack minHeight={'calc(100vh - 100px)'} alignItems={'center'} justifyContent={'center'}>
      <svg
        height="4rem"
        width="4rem"
        color="#fff"
        viewBox="0 0 464 403"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ color: theme.palette.primary.main, paddingLeft: '0.7rem' }}
      >
        <path
          id="logo-word"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M363.5 260H402.5V369.5H463.5V403H370.5V310.432L311.965 403L221 260H261.5L311.965 339L363.5 260Z"
          fill="currentColor"
        />
        <path
          id="logo-box"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.5 0.5H402.5V214.5H368.5V34.5H34.5V368.5H237L259 402.5H0.5V0.5Z"
          fill="currentColor"
        />
      </svg>
      <Stack alignItems={'center'} justifyContent={'center'} mt={3}>
        <Typography color={theme.palette.primary.main} variant="h1" fontWeight={800}>
          404
        </Typography>
        <Typography color="InfoText" variant="h4" fontWeight={600}>
          Page Not Found
        </Typography>
        <Button size="large" startIcon={<ArrowBack />} sx={{ marginTop: '2rem' }} onClick={back} variant="outlined">
          Go Back
        </Button>
      </Stack>
    </Stack>
  )
}
